import { FC } from 'react';
import { Tabs } from 'destiny/dist/components/molecules/tabs';
import { MenuItem } from 'destiny/dist/types';
import { trackMixpanel } from 'utils/mixpanel';

interface NavigationTabsProps {
  onChange: (item?: MenuItem) => void;
  list: MenuItem[];
  selectedTab?: MenuItem | null;
  id: string;
}

const NavigationTabs: FC<NavigationTabsProps> = ({ onChange, list, selectedTab, id }) => {
  const selectedIndex = list.findIndex((item) => item.id === selectedTab?.id);

  if (list.length <= 1) return null;

  return (
    <Tabs
      list={list}
      onSelect={onChange}
      customSelectedIndex={selectedIndex >= 0 ? selectedIndex : 0}
      id={id}
      eventCallback={trackMixpanel}
      wrapperClassName='!tw-w-[212px] tw-bg-white tw-p-1 tw-mb-2 tw-rounded-lg'
      tabItemGapClassName='tw-mr-1'
      indicatorClassName=''
      selectedTabIndicatorClassName=''
      tabItemClassName='tw-p-2 tw-w-full tw-text-center tw-justify-center tw-rounded-lg tw-bg-BASE_SECONDARY'
      tabItemSelectedClassName='f-12-600 tw-text-white tw-bg-GREEN_SECONDARY'
      tabItemDefaultClassName='f-12-400 tw-text-TEXT_SECONDARY'
      tabItemWrapperStyle='tw-w-full'
    />
  );
};

export default NavigationTabs;
