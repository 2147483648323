import { Children, cloneElement, FC, isValidElement, ReactNode, useEffect, useRef } from 'react';
import { Provider } from 'react-redux';
import { LAUNCH_DARKLY_FLAGS } from 'constants/launchDarkly';
import { useAppSelector } from 'hooks/toolkit';
import { RootState, store } from 'store';
import { CommonPageLayoutProps } from 'types';
import { getVariation } from 'utils/launchDarkly';
import NavigationSidebar from 'components/layout/NavigationSidebar';
import Sidebar from 'components/layout/Sidebar';
import DashboardTopbar from 'components/layout/topbar/DashboardTopbar';

type DashboardLayoutProps = {
  page: string;
  pageType?: string;
  children: React.ReactNode;
  className?: string;
};

const DashboardLayout: FC<DashboardLayoutProps> = ({ page, children, className = '' }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { navigation } = useAppSelector((state: RootState) => state.user);

  useEffect(() => {
    scrollToTop();
  }, [page]);

  const scrollToTop = () => {
    if (containerRef.current) containerRef.current.scrollTop = 0;
  };

  const renderChildrenWithProps = (children: ReactNode) => {
    const childrenWithProps = Children.map(children, (child) => {
      if (isValidElement(child))
        return cloneElement(child as React.ReactElement<CommonPageLayoutProps>, {
          scrollToTop: scrollToTop,
          rootContainerRef: containerRef,
        });

      return child;
    });

    return childrenWithProps;
  };

  const isOtcEnabled = getVariation(LAUNCH_DARKLY_FLAGS.OTC);

  return (
    <Provider store={store}>
      <DashboardTopbar config={navigation?.top_bar_configurations ?? navigation?.[0]?.top_bar_configurations} />
      <div
        className={`tw-w-full tw-min-w-[768px] tw-overflow-hidden tw-relative tw-flex ${
          isOtcEnabled ? 'tw-bg-BASE_SECONDARY tw-h-otcBody' : 'tw-h-body'
        }`}
      >
        {isOtcEnabled ? <NavigationSidebar /> : <Sidebar items={navigation?.nav_items ?? []} />}
        <div
          ref={containerRef}
          className={`tw-flex-grow tw-pt-6 tw-pb-[60px] tw-px-6 xl:tw-px-[70px] tw-overflow-y-scroll ${className}`}
        >
          <div className='tw-w-full tw-h-auto tw-mx-auto 2xl:tw-max-w-[1230px]'>
            {renderChildrenWithProps(children)}
          </div>
        </div>
      </div>
    </Provider>
  );
};

export default DashboardLayout;
