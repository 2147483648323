import { type FC, useCallback, useState } from 'react';
import { ACCESS_FLAGS } from 'constants/config';
import { OTC } from 'constants/index';
import { MenuItem } from 'destiny/dist/types';
import { useAppSelector } from 'hooks/toolkit';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { RootState } from 'store';
import { NavigationItemSchema } from 'types/config';
import { NavigationGroupType } from 'types/navigation';
import NavigationTabs from 'components/layout/NavigationTabs';
import SidebarProfileWrapper from 'components/layout/SidebarProfileWrapper';
import SidebarTabWrapper from 'components/layout/SidebarTabWrapper';

const NavigationSidebar: FC = () => {
  const { userAccessFlags, user, merchantDetails, navigation } = useAppSelector((state: RootState) => state.user);
  const router = useRouter();

  const navigationGroups = navigation as NavigationGroupType[];

  const list: MenuItem[] =
    navigationGroups?.map((item) => ({
      label: item.title,
      value: item.title,
      id: item.title,
    })) ?? [];

  const [currentTab, selectedCurrentTab] = useState<MenuItem>(router?.pathname.includes(OTC) ? list[0] : list[1]);
  const [dropdownState, setDropdownState] = useState(false);

  const accessFlag = userAccessFlags[ACCESS_FLAGS.IS_TREASURY_ENABLED]
    ? ACCESS_FLAGS.IS_TREASURY_ENABLED
    : ACCESS_FLAGS.IS_PAYOUTS_ENABLED;

  const handleTabSelect = (item?: MenuItem) => {
    if (item) {
      selectedCurrentTab(item);
      const navigationTab: NavigationGroupType =
        navigationGroups?.find((navItem) => navItem.title === item?.value) ?? navigationGroups[0];
      const defaultRoute =
        navigationTab.nav_items.find((route) => route.key === navigationTab.default_nav_key) ??
        navigationTab.nav_items[0];

      router.push(defaultRoute.route);
    }
  };

  const toggleDropdown = useCallback(() => {
    setDropdownState((prev) => !prev);
  }, []);

  const handleMouseLeave = () => {
    setDropdownState(false);
  };

  const selectedNavigationGroup =
    navigationGroups?.find((item) => item.title === currentTab?.value) ?? navigationGroups?.[0];

  return (
    <div className='tw-pt-6 tw-pl-[18px]' onMouseLeave={handleMouseLeave}>
      <NavigationTabs onChange={handleTabSelect} list={list} selectedTab={currentTab} id='NAVIGATION_TABS' />
      <div className='tw-rounded-lg tw-bg-white tw-py-4 tw-h-[calc(100%-94px)] tw-mb-11'>
        {selectedNavigationGroup?.nav_items.map((route: NavigationItemSchema) => (
          <Link href={route.route} key={route.key}>
            <SidebarTabWrapper
              name={route.display_name}
              iconId={route.iconId}
              iconCategory={route.iconCategory}
              isSelected={router.pathname === route.route}
              accessFlag={accessFlag}
              expanded
            />
          </Link>
        ))}
        <SidebarProfileWrapper
          expanded
          userName={user?.fullName}
          dropdownState={dropdownState}
          toggleDropdown={toggleDropdown}
          merchantName={merchantDetails?.name}
        />
      </div>
    </div>
  );
};

export default NavigationSidebar;
